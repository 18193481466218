import {useParams} from "react-router-dom";
import {Wrapper} from "./SendInvitationSummaryModal.styles";
import {useBaseScreenHook} from "../../../../../logic/core/base-screen.hook";
import {EventInvitationStatus} from "../../../../../logic/events/events.model";
import {Table} from "../../../../../components/Table/table";

const SendInvitationSummaryModal = ({
                                        onClose,
                                        invitationResults,
                                    }: { onClose: () => void, invitationResults: EventInvitationStatus[] }) => {
    const {organizationId} = useParams();
    const {navigate, t} = useBaseScreenHook();
    return <Wrapper>

        <div className="title">
          { t('invitationSummaryModal.deliveryStatus') }
        </div>

        <Table>
            <thead>
            <tr>
                <th>{ t('invitationSummaryModal.participant') }</th>
                <th>{ t('invitationSummaryModal.usedPlan') }</th>
                <th>{ t('invitationSummaryModal.status') }</th>
            </tr>
            </thead>
<tbody>
{
            invitationResults?.map((result, index) => {
                return <tr key={index}>
                    <td>{result.participant.bodyMemberName}</td>
                    <td>
                        {

                            result.result.success && !result.result.consumedCredit
                                ? <div className={'description'}>{ t('invitationSummaryModal.prevUsed') }</div>
                                : null
                        }
                        {
                            result.result.success && result.result.consumedCredit
                                ? <div className="description">
                                    {result.result.consumedCredit.description}
                                </div>
                                : null
                        }

                        {
                            !result.result.success
                                ? <div className={'description'}>{ t('invitationSummaryModal.noPlan') }</div>
                                : null
                        }
                    </td>
                    <td>
                        <div className={result.result.success ? 'status success' : 'status'}>
                            {result.result.success ? t('invitationSummaryModal.sent') : t('invitationSummaryModal.notSent')}
                        </div>
                    </td>
                </tr>
            })
}
</tbody>
        </Table>

        <div className="buttons">
            <button className="button text mutes" onClick={onClose}>{ t('invitationSummaryModal.close') }</button>
            {
                invitationResults?.some(result => !result.result.success) &&
                <button className="button" onClick={() => {
                    navigate(`/organization/${organizationId}/host/billings/pricing`)
                }}>{ t('invitationSummaryModal.buyPlan') }
                </button>
            }
        </div>

    </Wrapper>
}

export default SendInvitationSummaryModal;
