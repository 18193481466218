import React, {useEffect, useState} from 'react';
import {Wrapper} from "./OrganizationBillingsOrderSummary.styles";
import {useBaseScreenHook} from "../../../../../logic/core/base-screen.hook";
import {CreditInvoiceData, CreditToBuyWithDefinition} from "../../../../../logic/credits/credits.model";
import {buyCredits} from "../../../../../logic/credits/credits";
import {useParams} from "react-router-dom";
import {Failure} from "../../../../../logic/core/failure";
import ErrorModal from "../../../../../components/ErrorModal";

const OrganizationBillingsOrderSummary = ({
                                              invoiceData,
                                              onClose,
                                              creditsToBuy,
                                          }: {
    onClose: () => void,
    invoiceData: CreditInvoiceData | null,
    creditsToBuy: CreditToBuyWithDefinition[]
},) => {
    const {organizationId} = useParams();
    const [error, setError] = useState<Failure | null>(null);

    const {loading, setLoading, i18n, t} = useBaseScreenHook();
    const numberFormatter = new Intl.NumberFormat(i18n.language, {minimumFractionDigits: 2,  maximumFractionDigits: 2}).format;
    const [items, setItems] = useState<CreditToBuyWithDefinition[]>([])
    useEffect(() => {
        setItems(creditsToBuy);
        // eslint-disable-next-line
    }, []);

    const addQuantity = (index: number) => {
        items[index].quantity += 1;
        setItems([...items]);
    }
    const removeQuantity = (index: number) => {
        if (items[index].quantity <= 1) {
            items[index].quantity = 1;
        } else {
            items[index].quantity -= 1;
        }
        setItems([...items]);
    }

    const _order = () => {
        setLoading(true);
        buyCredits(organizationId!, {
            request: {
                credits: items.map((item) => {
                    return {
                        name: item.name,
                        quantity: item.quantity
                    }
                }),
                invoiceData,
            },
            continueUrl: `${window.location.origin}/organization/${organizationId}/host/billings/billings?paymentId=0`
        }).then((res) => {
            setLoading(false);
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }

            window.location.href = res.data!;
        });
    }

    const _calculateGrossPrice = (): number => {
        return  items.reduce((acc, item) => {
            return acc + (item.definition.priceGross * item.quantity);
        }, 0)
    }

    const _calculateNetPrice = (): number => {
        return  items.reduce((acc, item) => {
            return acc + (item.definition.priceNet * item.quantity);
        }, 0)
    }


    return <Wrapper>

        <div className="title">
            { t('billingsOrderSummary.summary') }
        </div>

        <div className="plans">
            {
                items.map((creditToBuy, index) => {
                    return <div className="plan" key={index}>
                        <div className="name">
                            {creditToBuy.name}
                        </div>
                        <div className="price">
                            {numberFormatter(creditToBuy.definition.priceGross)} zł
                        </div>
                        <div className="quantity">
                            x {creditToBuy.quantity}

                            <div className="change-quantity">
                                <div className="add" onClick={() => addQuantity(index)}>+</div>
                                <div className="remove" onClick={() => removeQuantity(index)}>-</div>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>

        <div className="prices">
            <div className="row">
                <div className="name">
                    { t('billingsOrderSummary.vat') }
                </div>

                <div className="value">
                    {
                        numberFormatter(_calculateGrossPrice() - _calculateNetPrice())
                    } zł
                </div>
            </div>
            <div className="row">
                <div className="name">
                    <strong>
                        { t('billingsOrderSummary.toPay') }
                    </strong>
                </div>

                <div className="value">
                    {
                        numberFormatter(_calculateGrossPrice())
                    } zł
                </div>
            </div>
        </div>

        <button className="button" onClick={_order} disabled={loading}>
            { t('billingsOrderSummary.goToPayment') }
        </button>

        <button className="button text muted" onClick={onClose} disabled={loading}>
            { t('billingsOrderSummary.cancel') }
        </button>


        <div className="info">
            { t('billingsOrderSummary.info') }
        </div>
        <ErrorModal failure={error} onClose={() => setError(null)}/>

    </Wrapper>
}

export default OrganizationBillingsOrderSummary;
